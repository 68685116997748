import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CrmApiService } from 'common-module/api';
import { CrmSafeAny } from 'common-module/core/types';
import { CrmMessageService } from 'common-module/message';
import { CrmTranslateService } from 'common-module/translate';
import { Observable, of, Subject, timer } from 'rxjs';
import {
  catchError,
  filter,
  share,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { UserApiService } from '~/api/user/user-api.service';

@Injectable({ providedIn: 'root' })
export class ConnectionService {
  private _checkingTimer?: Observable<CrmSafeAny>;

  private msgId?: string;

  private readonly messageService = inject(CrmMessageService);
  private readonly userService = inject(UserApiService);
  private readonly apiService = inject(CrmApiService);
  private readonly translateService = inject(CrmTranslateService);

  constructor() {
    this.userService.logout$.asObservable().subscribe(() => {
      this._closeMessage();
    });
  }

  public checkConnection(checkStatus = false) {
    if (this._checkingTimer) {
      return this._checkingTimer;
    }

    const reconnected$ = new Subject<void>();
    this.messageService
      .loading(this.translateService.get('reconnect.message'))
      .subscribe((id) => (this.msgId = id));

    const _check = () => {
      return this.apiService.health().pipe(
        catchError((err: HttpErrorResponse) =>
          of(checkStatus ? err.status !== 0 : null),
        ),
        filter((res) => !!res),
        tap(() => {
          reconnected$.next();
          reconnected$.complete();
          this._closeMessage();
        }),
      );
    };

    this._checkingTimer = timer(0, 5 * 1000).pipe(
      takeUntil(reconnected$),
      takeUntil(this.userService.logout$),
      switchMap(() => _check()),
      share(),
    );

    return this._checkingTimer;
  }

  private _closeMessage(): void {
    if (this.msgId) {
      this.messageService.closeMessage(this.msgId);
      delete this.msgId;
      this.messageService.success(
        this.translateService.get('reconnect.success'),
      );
    }
  }
}
